import gql from 'graphql-tag'

export const ADD_OWNER_NEW_PLAYER = gql`
    mutation (
        $phoneNumber: String
        $email: String
        $firstName: String
        $lastName: String
        $middleName: String
        $zipCode: String
        $state: String
        $county: String
        $city: String
        $colonia: String
        $street: String
        $numberExt: String
        $numberInt: String
        $country: String
        $birthDate: String
        $playerAttributes: [PlayerAttributeInput]
        $userId: String
        $playerId: String
    ) {
        ownerNewPlayer(
            newPlayer: {
                phoneNumber: $phoneNumber
                email: $email
                firstName: $firstName
                lastName: $lastName
                middleName: $middleName
                zipCode: $zipCode
                state: $state
                county: $county
                city: $city
                colonia: $colonia
                street: $street
                numberExt: $numberExt
                numberInt: $numberInt
                country: $country
                birthDate: $birthDate
                playerAttributes: $playerAttributes
                userId: $userId
                playerId: $playerId
            }
        ) {
            id
        }
    }
`

export const OWNER_NEW_PLAYER_QUERY = gql`
    {
        playerAttributesBySportAndType(sport: "SOCCER", type: "BASIC") {
            key
            name
            values
            description
        }
    }
`

export const GET_ADDRESS_BY_ZIP_CODE = gql`
    query Address($cp: String) {
        address(zipCode: $cp) {
            cp
            asentamiento
            municipio
            estado
            ciudad
            pais
        }
    }
`

export const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
