import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import MenuBar from '../MenuBar/MenuBar'

const UscorerPage = ({ children }) => {
    return (
        <Router>
            <MenuBar />
            {children}
        </Router>
    )
}

export default UscorerPage
