import { useQuery } from '@apollo/client'
import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    title: {
        // flexGrow: 1,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: 'bolder',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontWeight: 'bolder',
            color: theme.palette.primary.contrastText,
        },
    },
}))

const OWNER_HOME_PAGE_QUERY = gql`
    query {
        owner {
            leagues {
                id
                leagueName
            }
        }
    }
`

const OwnerInfo = ({ onOwnerInfo, leagueName }) => {
    const classes = useStyles()
    const { data, loading } = useQuery(OWNER_HOME_PAGE_QUERY)

    useEffect(() => {
        if (!loading) {
            onOwnerInfo(
                data.owner.leagues.length === 0,
                data.owner.leagues[0]?.id,
                data.owner.leagues[0]?.leagueName
            )
        }
    }, [data, loading, onOwnerInfo])

    return (
        <Typography className={classes.title}>
            <Link to="/" className={classes.title}>
                {leagueName}
            </Link>
        </Typography>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
        leagueName: state.ownerReducer.leagueName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOwnerInfo: (noLeague, leagueId, leagueName) => {
            dispatch(actions.ownerInfo(noLeague, leagueId, leagueName))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInfo)
