import * as actionTypes from './actionTypes'

export const ownerInfo = (noLeague, leagueId, leagueName) => {
    return {
        type: actionTypes.OWNER_INFO,
        noLeague: noLeague,
        leagueId: leagueId,
        leagueName: leagueName,
    }
}

export const ownerTournamentCreateStart = (teams) => {
    return {
        type: actionTypes.OWNER_TOURNAMENT_CREATE_START,
        teams: teams,
    }
}

export const ownerTeamEditStart = (team) => {
    return {
        type: actionTypes.OWNER_TEAM_EDIT_START,
        teamId: team.id,
        edit: true,
    }
}

export const ownerTeamEditEnd = () => {
    return {
        type: actionTypes.OWNER_TEAM_EDIT_END,
    }
}

export const ownerPlayerEditStart = (player) => {
    return {
        type: actionTypes.OWNER_PLAYER_EDIT_START,
        playerId: player.id,
        edit: true,
    }
}

export const ownerPlayerEditEnd = () => {
    return {
        type: actionTypes.OWNER_PLAYER_EDIT_END,
        playerId: '',
        edit: false,
    }
}
