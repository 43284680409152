import {
    Backdrop,
    Button,
    CircularProgress,
    makeStyles,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const URButton = (props) => {
    const { loading, children } = props
    const classes = useStyles()

    return (
        <>
            <Backdrop className={classes.backdrop} open={loading ?? false}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button fullWidth variant="contained" color="primary" {...props}>
                {children}
            </Button>
        </>
    )
}

export default URButton
