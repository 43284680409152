import React from 'react'
import UscorerPage from '../../components/UscorerPage/UscorerPage'

const DTHomePage = () => {
    return (
        <UscorerPage>
            <div>DT Home Page</div>
        </UscorerPage>
    )
}

export default DTHomePage
