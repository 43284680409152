import * as actionTypes from '../actions/actionTypes'
import { cloneDeep, assignIn } from 'lodash'

const initialState = {
    playerId: '',
    edit: false,
}

const updateObject = (object, updatedProperties) => {
    const newObject = cloneDeep(object)
    return assignIn(newObject, updatedProperties)
}

const ownerPlayerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OWNER_PLAYER_EDIT_START:
            return updateObject({
                playerId: action.playerId,
                edit: action.edit,
            })
        case actionTypes.OWNER_PLAYER_EDIT_END:
            return updateObject({
                playerId: '',
                edit: false,
            })
        default:
            return state
    }
}

export default ownerPlayerReducer
