import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './Login/Login'
import MenuBar from '../components/MenuBar/MenuBar'
import SignUp from './SignUp/SignUp'
import LeagueWall from './LeagueWall/LeagueWall'

const Public = () => {
    return (
        <Router>
            <MenuBar />
            <Switch>
                <Route exact path="/leagueWall">
                    <LeagueWall />
                </Route>
                <Route exact path="/signUp">
                    <SignUp />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    )
}

export default Public
