import { Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import UscorerPage from '../../components/UscorerPage/UscorerPage'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
}))

const PlayerHomePage = () => {
    const classes = useStyles()

    return (
        <UscorerPage>
            <Container maxWidth="sm" className={classes.container}>
                <Grid
                    container
                    spacing={5}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    Player Home Page
                </Grid>
            </Container>
        </UscorerPage>
    )
}

export default PlayerHomePage
