import { useMutation } from '@apollo/client'
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useCallback, useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import { connect } from 'react-redux'
import { AUTH_TOKEN } from '../../constants'
import * as actions from '../../store/actions/index'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    userType: {
        width: 300,
        height: 300,
    },
    facebookButton: {
        width: '100%',
        minWidth: '400px',
        backgroundColor: '#4267B2',
        fontFamily: 'Helvetica,sans-serif',
        fontWeight: '600',
        color: '#fff',
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: 'calc(.27548vw + 10.71074px)',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'background-color .3s,border-color .3s',
        border: 'calc(.06887vw + .67769px) solid #4c69ba',
        padding: 'calc(.34435vw + 11.38843px) calc(.34435vw + 15.38843px)',
    },
}))

const SIGN_UP = gql`
    mutation SignUp(
        $firstName: String
        $lastName: String
        $email: String
        $password: String
        $pictureUrl: String
        $type: String
    ) {
        signUp(
            user: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                password: $password
                pictureUrl: $pictureUrl
                type: $type
            }
        ) {
            id
            firstName
            lastName
            pictureUrl
            type
            token
        }
    }
`

const SignUp = ({ onAuth, onAuthSuccess }) => {
    const classes = useStyles()
    const [signUp] = useMutation(SIGN_UP)
    const [userTypeSelected, setUserTypeSelected] = useState(false)
    const [signUpWithEmail, setSignUpWithEmail] = useState(false)
    const [user, setUser] = useState({})

    const handleUserType = (type) => {
        setUser({
            ...user,
            type,
        })
        setUserTypeSelected(!userTypeSelected)
    }

    const handleSignUpEmailClick = () => {
        setSignUpWithEmail(!signUpWithEmail)
    }

    const handleInputOnChange = (event) => {
        event.preventDefault()
        setUser({
            ...user,
            [event.target.id]: event.target.value,
        })
    }

    const handleFacebookResponse = (response) => {
        console.log(response)
        setUser({
            firstName: response.name,
            email: response.email,
            pictureUrl: response.picture.data.url,
            type: user.type,
            password: user.password,
            facebookLogin: true,
        })
    }

    const handleCreateAccount = useCallback(
        (user) => {
            onAuth(user.email, user.password, false)
            signUp({
                variables: {
                    ...user,
                },
            })
                .then((response) => {
                    const { id, token, pictureUrl, firstName, lastName, type } =
                        response.data.signUp
                    localStorage.setItem(AUTH_TOKEN, token)
                    onAuthSuccess(
                        id,
                        token,
                        firstName,
                        lastName,
                        pictureUrl,
                        type
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [onAuth, signUp, onAuthSuccess]
    )

    useEffect(() => {
        if (user.facebookLogin) {
            handleCreateAccount(user)
        }
    }, [user, handleCreateAccount])

    return (
        <Container maxWidth="lg" className={classes.container}>
            {!userTypeSelected && (
                <Grid
                    container
                    spacing={5}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={12}>
                        <Typography
                            variant="h5"
                            color="secondary"
                            component="h2"
                        >
                            Which type of user are you?
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} lg={3}>
                        <Card
                            className={classes.userType}
                            onClick={() => handleUserType('OWNER')}
                        >
                            <CardContent>
                                <Typography>Propietario de Liga</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={3} lg={3}>
                        <Card
                            className={classes.userType}
                            onClick={() => handleUserType('DT')}
                        >
                            <CardContent>
                                <Typography>DT de Equipo(s)</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={3} lg={3}>
                        <Card
                            className={classes.userType}
                            onClick={() => handleUserType('PLAYER')}
                        >
                            <CardContent>
                                <Typography>Jugador</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}

            {userTypeSelected && (
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                        >
                            <Grid item>
                                <Typography
                                    variant="h5"
                                    color="secondary"
                                    component="h2"
                                >
                                    Sign up to start your career with Uscorer
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box style={{ textAlign: 'center' }}>
                                    <FacebookLogin
                                        cssClass={classes.facebookButton}
                                        appId="160834444087767"
                                        autoLoad={false}
                                        fields="name, email, picture"
                                        textButton="Sign Up with Facebook"
                                        callback={handleFacebookResponse}
                                    />
                                </Box>
                            </Grid>
                            {!signUpWithEmail && (
                                <Grid item>
                                    <Box style={{ textAlign: 'center' }}>
                                        <Typography color="secondary">
                                            Or
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item>
                                <Box style={{ textAlign: 'center' }}>
                                    {!signUpWithEmail && (
                                        <Button
                                            onClick={handleSignUpEmailClick}
                                        >
                                            Sign up with E-Mail
                                        </Button>
                                    )}
                                    {signUpWithEmail && (
                                        <Typography color="secondary">
                                            Sign up with E-Mail
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            {signUpWithEmail && (
                                <>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="firstName"
                                                label="First Name"
                                                variant="outlined"
                                                value={user.firstname}
                                                onChange={handleInputOnChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="lastName"
                                                label="Last Name"
                                                variant="outlined"
                                                value={user.lastName}
                                                onChange={handleInputOnChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                value={user.email}
                                                onChange={handleInputOnChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="password"
                                                id="password"
                                                label="Password"
                                                variant="outlined"
                                                value={user.password}
                                                onChange={handleInputOnChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleCreateAccount}
                                            >
                                                Create Account
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.token !== null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, isSignUp) => {
            dispatch(actions.authUser(email, password, isSignUp))
        },
        onAuthSuccess: (
            userId,
            token,
            firstName,
            lastName,
            pictureUrl,
            type
        ) => {
            dispatch(
                actions.authSuccess(
                    userId,
                    token,
                    firstName,
                    lastName,
                    pictureUrl,
                    type
                )
            )
        },
        onAuthFail: (error) => {
            dispatch(actions.authFail(error))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
