import { Avatar, Button, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import URFormSection from './URFormSection'

const useStyles = makeStyles((theme) => ({
    profile: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

const URFormProfilePicture = ({ title, subtitle }) => {
    const classes = useStyles()

    return (
        <URFormSection title={title} subtitle={subtitle}>
            <Grid container spacing={3}>
                <Grid item xs={4} sm={2} lg={2}>
                    <Avatar className={classes.profile}>P</Avatar>
                </Grid>
                <Grid item xs={8} sm={10} lg={10}>
                    <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="icon-button-file">
                        <Button
                            variant="outlined"
                            component="span"
                            size="large"
                            color="primary"
                        >
                            Choose a Photo
                            {/* <ImageIcon className={classes.extendedIcon} /> */}
                        </Button>
                    </label>
                </Grid>
            </Grid>
        </URFormSection>
    )
}

export default URFormProfilePicture
