import React from 'react'
import ReactDOM from 'react-dom'

// import '@fontsource/roboto'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { AUTH_TOKEN } from './constants'
import authReducer from './store/reducers/auth'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import Auth from './Auth/Auth'
import { SnackbarProvider } from 'notistack'
import ownerReducer from './store/reducers/owner'
import ownerMenuReducer from './store/reducers/ownermenu'
import ownerTournamentReducer from './store/reducers/ownertournament'
import ownerTeamReducer from './store/reducers/ownerteam'
import ownerPlayerReducer from './store/reducers/ownerplayer'

const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : composeWithDevTools || composeWithDevTools

const logger = (store) => {
    return (next) => {
        return (action) => {
            const result = next(action)
            return result
        }
    }
}

const rootReducer = combineReducers({
    authReducer: authReducer,
    ownerReducer: ownerReducer,
    ownerMenuReducer: ownerMenuReducer,
    ownerTournamentReducer: ownerTournamentReducer,
    ownerTeamReducer: ownerTeamReducer,
    ownerPlayerReducer: ownerPlayerReducer,
})

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(logger))
)

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#2c2c2c',
            main: '#000000',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#6ee86b',
            main: '#33b53c',
            dark: '#008402',
            contrastText: '#000000',
        },
    },
    typography: {
        fontFamily: [
            '"Segoe UI"',
            'Roboto',
            'Arial',
            'sans-serif',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 13,
    },
    overrides: {
        // Style sheet name ⚛️
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            },
        },
        MuiButton: {
            // Name of the rule
            root: {
                // Some CSS
                // background: 'linear-gradient(45deg, #7694e4 30%, #4267b2 90%)',
                borderRadius: 0,
                fontWeight: 'bolder',
            },
        },
    },
})

const httpLink = createHttpLink({
    uri: 'https://le1mtkt3x4.execute-api.us-west-2.amazonaws.com/dev/graphql',
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN)

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    withCredentials: false,
})

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={3}>
                        <Auth />
                    </SnackbarProvider>
                </Provider>
            </ApolloProvider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
