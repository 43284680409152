import React from 'react'
import {
    URFormContextConsumer,
    URFormContextProvider,
} from './context/URFormContext'

const URForm = ({ children, initialState, onSubmit, initialization }) => {
    return (
        <URFormContextProvider
            initialState={initialState}
            onSubmit={onSubmit}
            initialization={initialization}
        >
            <URFormContextConsumer>
                {(urFormContext) => (
                    <form
                        onSubmit={urFormContext.handleOnSubmitContext}
                        autoComplete="off"
                    >
                        {children}
                    </form>
                )}
            </URFormContextConsumer>
        </URFormContextProvider>
    )
}

export default URForm
