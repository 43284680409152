import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    makeStyles,
    Typography,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    teamShield: {
        [theme.breakpoints.up('xs')]: {
            height: 125,
            backgroundSize: '100px 125px',
        },
        width: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
            'url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTUwLjE3MSw2LjI4OGMxMS42MzcsOC4wMTMsMzAuMTkxLDExLjg5OCwzOC4xMDEsMTMuMjZjMC4zMDEsMi43NzIsMC40NTUsNS41MjcsMC40NTUsOC4yMjMgIGMwLDI3LjYzNC0xNC43MjgsNTIuOTMtMzguNjEyLDY2LjY0MkMyNi4yMjksODAuNjk3LDExLjUsNTUuNDAyLDExLjUsMjcuNzcxYzAtMi42NjksMC4xNTQtNS40MDMsMC40NTUtOC4xNyAgQzIwLjAzMSwxOC4zMTgsMzguOTQ2LDE0LjU2OCw1MC4xNzEsNi4yODggTTUwLjAzOSwwQzM4LjIxNiwxMS42OTksNy41ODgsMTUuMjgxLDcuNTg4LDE1LjI4MWMtMC42MjgsNC4wNzgtMC45OCw4LjI0LTAuOTgsMTIuNDkgIGMwLDMxLjMyNywxNy42NCw1OC41MTIsNDMuNTA2LDcyLjIyOWMyNS44Ny0xMy43MTgsNDMuNTA0LTQwLjkwMiw0My41MDQtNzIuMjI5YzAtNC4yNS0wLjM0Ni04LjQxMi0wLjk3My0xMi40OSAgQzkyLjY0NiwxNS4yODEsNjIuODIyLDExLjM0LDUwLjAzOSwwTDUwLjAzOSwweiI+PC9wYXRoPjxwYXRoIGQ9Ik0yMC43MzgsMjYuMDE1Yy0wLjQzNSwyLjgyNC0wLjY3Nyw1LjcwMi0wLjY3Nyw4LjYzOGMwLDIxLjYzMywxMi4xOSw0MC40MDcsMzAuMDUzLDQ5Ljg4MiAgYzE3Ljg2NC05LjQ3NiwzMC4wNTItMjguMjQ5LDMwLjA1Mi00OS44ODJjMC0yLjkzNi0wLjI0Ny01LjgxNC0wLjY3NC04LjYzOGMwLDAtMjAuNjAyLTIuNzE5LTI5LjQzMi0xMC41NTEgIEM0MS44OTIsMjMuNTQyLDIwLjczOCwyNi4wMTUsMjAuNzM4LDI2LjAxNXoiPjwvcGF0aD48L3N2Zz4=")',
    },
}))

const TeamCard = ({ team, onClick, className }) => {
    const classes = useStyles()

    const teamShield = () => {
        return <Box className={classes.teamShield} />
    }

    return (
        <Card key={team.id} className={className} variant="outlined">
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component={teamShield}
                    alt="Team name"
                    title="Team name"
                />
                <CardContent>
                    <Typography>{team.teamName}</Typography>
                    <Typography>
                        {team?.dt?.user?.firstName} {team?.dt?.user?.lastName}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default TeamCard
