import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    makeStyles,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 270,
        maxHeight: 270,
    },
    selected: {
        borderColor: theme.palette.primary.main,
        background: 'rgba(0,0,0,0.1)'
    },
    elementShield: {
        [theme.breakpoints.up('xs')]: {
            height: 125,
            backgroundSize: '100px 125px',
        },
        width: '100%',
        backgroundSize: '200px 200px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: (props) => props.backgroundImage,
    },
    elementCardContent: {
        borderTopStyle: 'solid',
        borderTopColor: '#33b53c',
        borderTopWidth: '2px',
        borderTopRightRadius: '25px',
        borderTopLeftRadius: '25px',
    },
}))

const UscorerCard = ({
    onEdit,
    onRemove,
    elements,
    element,
    setElements,
    backgroundImage,
    children,
}) => {
    const classes = useStyles({ backgroundImage: backgroundImage })

    const handleClickElement = (element) => {
        const elementIndex = elements.findIndex((e) => e.id === element.id)
        const existingElement = elements[elementIndex]
        const newElement = {
            ...existingElement,
            selected: !existingElement.selected,
        }
        setElements([
            ...elements.slice(0, elementIndex),
            newElement,
            ...elements.slice(elementIndex + 1),
        ])
    }

    const handleEnterElement = (element) => {
        const elementIndex = elements.findIndex((e) => e.id === element.id)
        const existingElement = elements[elementIndex]
        const newElement = {
            ...existingElement,
            over: true,
        }
        setElements([
            ...elements.slice(0, elementIndex),
            newElement,
            ...elements.slice(elementIndex + 1),
        ])
    }

    const handleOutElement = (element) => {
        const elementIndex = elements.findIndex((e) => e.id === element.id)
        const existingElement = elements[elementIndex]
        const newElement = {
            ...existingElement,
            over: false,
        }
        setElements([
            ...elements.slice(0, elementIndex),
            newElement,
            ...elements.slice(elementIndex + 1),
        ])
    }

    return (
        <Card
            variant="outlined"
            className={classes.root && element.selected ? classes.selected : ''}
        >
            <CardActionArea onClick={(event) => handleClickElement(element)}>
                {element.over ? (
                    <CardMedia
                        onMouseLeave={(event) => {
                            handleOutElement(element)
                        }}
                    >
                        <Box
                            style={{
                                width: '100%',
                                height: '133px',
                            }}
                            onMouseLeave={(event) => {
                                handleOutElement(element)
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justify="center"
                                spacing={2}
                                style={{
                                    height: '100%',
                                }}
                            >
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="secondary"
                                        onClick={(event) => {
                                            onRemove(element)
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={(event) => onEdit(element)}
                                    >
                                        Edit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardMedia>
                ) : (
                    <CardMedia
                        onMouseOver={(event) => handleEnterElement(element)}
                    >
                        <Box
                            className={classes.elementShield}
                            onMouseOver={(event) => handleEnterElement(element)}
                        />
                    </CardMedia>
                )}

                <CardContent className={classes.elementCardContent}>
                    {children}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default UscorerCard
