import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { URFormContext } from './context/URFormContext'

const URFormTextField = (props) => {
    const { id, inputProps, inputRegex, children, select } = props
    const {
        fields,
        touched,
        errors,
        handleOnChange,
        handleOnBlur,
        handleOnChangeSelect,
        schema,
    } = useContext(URFormContext)

    const handleChange = (event, child) => {
        select
            ? handleOnChangeSelect(event, child)
            : handleOnChange(event, inputRegex)
    }

    return (
        <TextField
            value={fields[id]}
            onChange={handleChange}
            onBlur={handleOnBlur}
            error={touched[id] && Boolean(errors[id])}
            helperText={touched[id] && errors[id]}
            placeholder={schema[id]}
            InputProps={{
                ...inputProps,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        >
            {children}
        </TextField>
    )
}

URFormTextField.propTypes = {
    __TYPE: PropTypes.string,
}

URFormTextField.defaultProps = {
    __TYPE: 'URFormTextField',
}

export default URFormTextField
