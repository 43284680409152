import { Container, Grid, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router'
import Publication from './Publication/Publication'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
        },
    },
    sideColumn: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        backgroundColor: 'darkgray',
        position: 'fixed',
        width: '300px',
        height: '800px',
    },
}))

const events = [
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
    {
        id: '1',
        type: 'Game',
        data: { firstTeam: 'America', secondTeam: 'Bayern' },
    },
]

const LeagueWall = () => {
    const classes = useStyles()
    const { id } = useParams()

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={3} lg={3}>
                    <Paper className={classes.sideColumn}></Paper>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        spacing={2}
                    >
                        {events.map((event) => (
                            <Grid item xs={12} sm={12} lg={12}>
                                <Publication />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    <Paper className={classes.sideColumn}></Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LeagueWall
