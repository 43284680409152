import React, { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Grid, MenuItem } from '@material-ui/core'
import URFormSection from './URFormSection'
import URFormTextField from './URFormTextField'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { URFormContext } from './context/URFormContext'
import { GET_ADDRESS_BY_ZIP_CODE } from '../../Owner/OwnerHomePage/OwnerPlayer/OwnerNewPlayer/constants'

const urFormAddressValidationSchema = {
    zipCode: yup
        .string('Enter your zip code')
        .length(5, 'Zip code should be of maximum 5 characters length')
        .required('Zip code is required'),
    street: yup
        .string('Enter your street')
        .max(30, 'Street should be of maximum 30 characters length')
        .required('Street is required'),
    colonia: yup
        .string('Enter your street')
        .max(30, 'Colonia should be of maximum 30 characters length')
        .required('Colonia is required'),
    numberExt: yup
        .string('Enter your Ext. Number')
        .max(50, 'Ext. Number should be of maximum 50 characters length')
        .required('Ext. Number is required'),
    numberInt: yup
        .string('Enter your Int. Number')
        .max(50, 'Int. Number should be of maximum 50 characters length')
        .optional(),
}

const URFormAddressSection = ({ title, subtitle }) => {
    const [zipcodeloaded, setzipcodeloaded] = useState(false)
    const { addValidationSchema, fields, handleOnChangeSelect } =
        useContext(URFormContext)
    addValidationSchema(urFormAddressValidationSchema)

    const [getAddress, { loading, data }] = useLazyQuery(
        GET_ADDRESS_BY_ZIP_CODE
    )

    const handleZipCodeOnBlur = (event) => {
        getAddress({
            variables: {
                cp: event.target.value,
            },
        })
    }

    useEffect(() => {
        if (!zipcodeloaded && fields.zipCode) {
            handleZipCodeOnBlur({
                target: {
                    value: fields.zipCode,
                },
            })
            setzipcodeloaded(!zipcodeloaded)
        }
    }, [fields?.zipCode, zipcodeloaded, handleZipCodeOnBlur])

    return (
        <URFormSection title={title} subtitle={subtitle} loading={loading}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        fullWidth
                        id="zipCode"
                        label="Codigo Postal"
                        variant="outlined"
                        inputProps={{
                            maxLength: 5,
                        }}
                        onBlur={handleZipCodeOnBlur}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        disabled
                        fullWidth
                        id="state"
                        label="Estado"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                        value={data?.address?.estado}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        disabled
                        fullWidth
                        id="county"
                        label="Municipio"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                        value={data?.address?.municipio}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    {data?.address?.asentamiento ? (
                        <URFormTextField
                            select
                            fullWidth
                            id="colonia"
                            label="Colonia"
                            variant="outlined"
                            inputProps={{
                                maxLength: 50,
                            }}
                        >
                            {data.address.asentamiento.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option}
                                    id="colonia"
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </URFormTextField>
                    ) : (
                        <URFormTextField
                            disabled
                            fullWidth
                            label="Colonia"
                            variant="outlined"
                            inputProps={{
                                maxLength: 50,
                            }}
                        ></URFormTextField>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <URFormTextField
                        fullWidth
                        id="street"
                        label="Street"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    <URFormTextField
                        fullWidth
                        id="numberExt"
                        label="Ext. Number"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    <URFormTextField
                        fullWidth
                        id="numberInt"
                        label="Int. Number"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
            </Grid>
        </URFormSection>
    )
}

URFormAddressSection.propTypes = {
    __TYPE: PropTypes.string,
}

URFormAddressSection.defaultProps = {
    __TYPE: 'URFormAddressSection',
}

export default URFormAddressSection
