import * as actionTypes from '../actions/actionTypes'
import { cloneDeep, assignIn } from 'lodash'

const initialState = {
    teams: [],
}

const updateObject = (object, updatedProperties) => {
    const newObject = cloneDeep(object)
    return assignIn(newObject, updatedProperties)
}

const ownerTournamentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OWNER_TOURNAMENT_CREATE_START:
            return updateObject({
                teams: action.teams,
            })
        default:
            return state
    }
}

export default ownerTournamentReducer
