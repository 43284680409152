import * as actionTypes from '../actions/actionTypes'
import { cloneDeep, assignIn } from 'lodash'

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    isAuthenticated: false,
    authRedirectPath: '/',
    firstName: '',
    lastName: '',
    pictureUrl: '',
    userType: '',
}

const updateObject = (object, updatedProperties) => {
    const newObject = cloneDeep(object)
    return assignIn(newObject, updatedProperties)
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_USER:
            return updateObject(state, {})
        case actionTypes.AUTH_START:
            return updateObject(state, {
                error: null,
                loading: true,
            })
        case actionTypes.AUTH_SUCCESS:
            return updateObject(state, {
                error: null,
                loading: false,
                isAuthenticated: action.token != null,
                token: action.token,
                userId: action.userId,
                firstName: action.firstName,
                lastName: action.lastName,
                pictureUrl: action.pictureUrl,
                userType: action.userType,
            })
        case actionTypes.AUTH_FAIL:
            return updateObject(state, {
                error: action.error,
                loading: false,
            })
        case actionTypes.LOGOUT:
            return updateObject(state, {
                loading: true,
            })
        case actionTypes.LOGOUT_SUCCESS:
            return updateObject(state, {
                ...initialState,
            })
        default:
            return state
    }
}

export default authReducer
