import { useMutation } from '@apollo/client'
import { Grid } from '@material-ui/core'
import gql from 'graphql-tag'
import React from 'react'
import URButton from '../../../components/URForm/URButton'
import URForm from '../../../components/URForm/URForm'
import URFormAddressSection from '../../../components/URForm/URFormAddressSection'
import URFormBasicData from '../../../components/URForm/URFormBasicData'
import URFormProfilePicture from '../../../components/URForm/URFormProfilePicture'

const OWNER_PROFILE_QUERY = gql`
    {
        owner {
            user {
                id
                firstName
                lastName
                middleName
                email
                phoneNumber
                zipCode
                state
                county
                colonia
                street
                numberExt
                numberInt
            }
        }
    }
`

const OWNER_UPDATE_MUTATION = gql`
    mutation OwnerUpdateMutation(
        $id: String
        $phoneNumber: String
        $email: String
        $password: String
        $firstName: String
        $lastName: String
        $middleName: String
        $zipCode: String
        $state: String
        $county: String
        $city: String
        $colonia: String
        $street: String
        $numberExt: String
        $numberInt: String
        $country: String
        $pictureUrl: String
    ) {
        updateUser(
            user: {
                id: $id
                phoneNumber: $phoneNumber
                email: $email
                password: $password
                firstName: $firstName
                lastName: $lastName
                middleName: $middleName
                zipCode: $zipCode
                state: $state
                county: $county
                city: $city
                colonia: $colonia
                street: $street
                numberExt: $numberExt
                numberInt: $numberInt
                country: $country
                pictureUrl: $pictureUrl
            }
        ) {
            id
        }
    }
`

const OwnerProfile = () => {
    const [ownerUpdate, { loading }] = useMutation(OWNER_UPDATE_MUTATION)

    const handleOnSubmit = (fields) => {
        ownerUpdate({
            variables: {
                ...fields,
            },
        })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <URForm
            onSubmit={handleOnSubmit}
            initialization={{
                query: OWNER_PROFILE_QUERY,
                path: 'owner.user',
            }}
        >
            <URFormProfilePicture
                title="Profile Picture"
                subtitle="Elige una foto de perfil"
            />

            <URFormBasicData
                title="Owner Contact Info"
                subtitle="Introduce tu informacion de contacto"
            />

            <URFormAddressSection
                title="Owner Address"
                subtitle="Introduce tu direccion"
            />

            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} lg={6}>
                    <URButton type="submit" loading={loading}>
                        Save
                    </URButton>
                </Grid>
            </Grid>
        </URForm>
    )
}

export default OwnerProfile
