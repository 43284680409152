import { useLazyQuery } from '@apollo/client'
import React, { createContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import * as yup from 'yup'
import { OWNER_NEW_PLAYER_QUERY } from '../../../Owner/OwnerHomePage/OwnerPlayer/OwnerNewPlayer/constants'

export const URFormContext = createContext({})

export const URFormContextProvider = ({
    initialState,
    onSubmit,
    initialization,
    children,
}) => {
    const { query, variables, path, transformData } = initialization ?? {}
    const [initializationQuery, { data, loading }] = useLazyQuery(
        query ? query : OWNER_NEW_PLAYER_QUERY
    )
    const [fields, setFields] = useState(initialState ?? {})
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState({})
    let schema = {}

    useEffect(() => {
        if (initialization) {
            initializationQuery({
                variables: {
                    ...variables,
                },
            })
        }
    }, [initializationQuery])

    useEffect(() => {
        if (initialization && data && !loading) {
            const values = path ? get(data, path) : data
            setFields(transformData ? transformData(values) : values)
        }
    }, [data, loading, path, transformData])

    const handleOnChange = (event, inputRegex) => {
        event.preventDefault()

        const field = event.target.id
        const value = event.target.value

        setFields({
            ...fields,
            [field]:
                inputRegex && value !== ''
                    ? inputRegex.test(value)
                        ? value
                        : fields[field] ?? ''
                    : value,
        })
    }

    const handleOnChangeSelect = (event, child) => {
        event.preventDefault()

        const { id, value } = child.props

        setFields({
            ...fields,
            [id]: value,
        })
    }

    const handleOnBlur = (event) => {
        event.preventDefault()

        const field = event.target.id
        if (!touched[field]) {
            setTouched({
                ...touched,
                [event.target.id]: true,
            })
        }

        validate()
    }

    const handleOnKeyUp = (event) => {
        event.preventDefault()
    }

    const validate = async () => {
        return yup
            .object(schema)
            .validate(fields, { abortEarly: false })
            .then((res) => {
                setErrors({})
            })
            .catch((err) => {
                const { inner } = err
                if (!inner) {
                    console.log('There are no errors')
                    console.log(err)
                    return
                }

                const validationErrors = inner.map((error) => {
                    return [error.path, error.message]
                })

                setErrors({
                    ...Object.fromEntries(validationErrors),
                })
            })
    }

    const handleOnSubmitContext = async (event) => {
        event.preventDefault()
        validate().then(() => {
            onSubmit(fields)
        })
    }

    const addValidationSchema = (validationSchema) => {
        schema = {
            ...schema,
            ...validationSchema,
        }
    }

    const urFormContext = {
        fields,
        errors,
        touched,
        handleOnSubmitContext,
        handleOnChangeSelect,
        handleOnChange,
        handleOnKeyUp,
        handleOnBlur,
        addValidationSchema,
        schema,
        formLoading: loading,
    }

    return (
        <URFormContext.Provider value={urFormContext}>
            {children}
        </URFormContext.Provider>
    )
}

export const URFormContextConsumer = URFormContext.Consumer
