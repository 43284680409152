import { useMutation } from '@apollo/client'
import { Grid } from '@material-ui/core'
import gql from 'graphql-tag'
import React from 'react'
import { connect } from 'react-redux'
import URButton from '../../../components/URForm/URButton'
import URForm from '../../../components/URForm/URForm'
import URFormAddressSection from '../../../components/URForm/URFormAddressSection'
import URFormProfilePicture from '../../../components/URForm/URFormProfilePicture'
import URFormSection from '../../../components/URForm/URFormSection'
import URFormTextField from '../../../components/URForm/URFormTextField'

const ADD_LEAGUE = gql`
    mutation AddLeague(
        $id: String
        $leagueName: String
        $description: String
        $regulation: String
        $zipCode: String
        $state: String
        $county: String
        $city: String
        $colonia: String
        $street: String
        $numberExt: String
        $numberInt: String
        $country: String
    ) {
        addLeague(
            league: {
                id: $id
                leagueName: $leagueName
                description: $description
                regulation: $regulation
                zipCode: $zipCode
                state: $state
                county: $county
                city: $city
                colonia: $colonia
                street: $street
                numberExt: $numberExt
                numberInt: $numberInt
                country: $country
            }
        ) {
            id
            leagueName
        }
    }
`

const OWNER_LEAGUE_QUERY = gql`
    query League($id: String) {
        league(id: $id) {
            id
            leagueName
            description
            regulation
            zipCode
            state
            county
            city
            colonia
            street
            numberExt
            numberInt
            country
        }
    }
`

const OwnerLeague = ({ leagueId }) => {
    const [addLeague, { loading }] = useMutation(ADD_LEAGUE)

    const handleOnSubmit = (fields) => {
        addLeague({
            variables: {
                ...fields,
            },
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <URForm
            onSubmit={handleOnSubmit}
            initialization={{
                query: OWNER_LEAGUE_QUERY,
                variables: {
                    id: leagueId,
                },
                path: 'league',
            }}
        >
            <URFormProfilePicture
                title="League Logo"
                subtitle="Elige una imagen para tu liga"
            />

            <URFormSection title="League Info">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <URFormTextField
                            fullWidth
                            id="leagueName"
                            label="League Name"
                            variant="outlined"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <URFormTextField
                            fullWidth
                            id="description"
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={4}
                            inputProps={{
                                maxLength: 1000,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <URFormTextField
                            fullWidth
                            id="regulation"
                            label="Regulation"
                            variant="outlined"
                            multiline
                            rows={8}
                            inputProps={{
                                maxLength: 1000,
                            }}
                        />
                    </Grid>
                </Grid>
            </URFormSection>

            <URFormAddressSection
                title="League Adress"
                subtitle="Introduce la direccion de la liga"
            />

            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} lg={6}>
                    <URButton type="submit" loading={loading}>
                        Save
                    </URButton>
                </Grid>
            </Grid>
        </URForm>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
    }
}

export default connect(mapStateToProps)(OwnerLeague)
