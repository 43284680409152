import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import URFormSection from './URFormSection'
import URFormTextField from './URFormTextField'
import { URFormContext } from './context/URFormContext'
import * as yup from 'yup'
import { PHONE_REGEXP } from '../../Owner/OwnerHomePage/OwnerPlayer/OwnerNewPlayer/constants'

const urFormBasicDataValidationSchema = {
    firstName: yup
        .string('Enter your first name')
        .min(3, 'First name should be of minimum 3 characters length')
        .max(50, 'First name should be of maximum 50 characters length')
        .required('First name is required'),
    lastName: yup
        .string('Enter your last name')
        .min(3, 'Last name should be of minimum 3 characters length')
        .max(50, 'Last name should be of maximum 50 characters length')
        .required('Last name is required'),
    middleName: yup
        .string('Enter your middle name')
        .min(3, 'Middle name should be of minimum 3 characters length')
        .max(50, 'Middle Name should be of maximum 50 characters length')
        .optional(),
    email: yup
        .string('Enter you E-mail')
        .min(5, 'E-mail should be of minimum 5 characters length')
        .max(100, 'E-mail should be of maximum 100 characters length')
        .email('Enter a valid email')
        .required('Email is required'),
    phoneNumber: yup
        .string('Enter your phone number')
        .matches(PHONE_REGEXP, 'Invalid phone')
        .required('Phone number is required'),
}

const URFormBasicData = ({ title, subtitle }) => {
    const { addValidationSchema } = useContext(URFormContext)
    addValidationSchema(urFormBasicDataValidationSchema)

    return (
        <URFormSection title={title} subtitle={subtitle}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        fullWidth
                        id="firstName"
                        label="First Name"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        fullWidth
                        id="middleName"
                        label="Middle Name"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={8} lg={8}>
                    <URFormTextField
                        fullWidth
                        id="email"
                        label="E-mail"
                        variant="outlined"
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <URFormTextField
                        fullWidth
                        id="phoneNumber"
                        label="Phone number"
                        variant="outlined"
                        inputRegex={/^[0-9\b]+$/}
                        inputProps={{
                            maxLength: 10,
                        }}
                    />
                </Grid>
            </Grid>
        </URFormSection>
    )
}

export default URFormBasicData
