import * as actionTypes from '../actions/actionTypes'
import { cloneDeep, assignIn } from 'lodash'

const initialState = {
    leagueName: '',
}

const updateObject = (object, updatedProperties) => {
    const newObject = cloneDeep(object)
    return assignIn(newObject, updatedProperties)
}

const ownerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OWNER_INFO:
            return updateObject(state, {
                noLeague: action.noLeague,
                leagueId: action.leagueId,
                leagueName: action.leagueName,
            })
        default:
            return state
    }
}

export default ownerReducer
