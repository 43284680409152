export const AUTH_START = 'AUTH_START'
export const AUTH_USER = 'AUTH_USER'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const OWNER_INFO = 'OWNER_INFO'
export const OWNER_MENU_SELECTED = 'OWNER_MENU_SELECTED'
export const OWNER_TOURNAMENT_CREATE_START = 'OWNER_TOURNAMENT_CREATE_START'
export const OWNER_TEAM_EDIT_START = 'OWNER_TEAM_EDIT_START'
export const OWNER_TEAM_EDIT_END = 'OWNER_TEAM_EDIT_END'
export const OWNER_PLAYER_EDIT_START = 'OWNER_PLAYER_EDIT_START'
export const OWNER_PLAYER_EDIT_END = 'OWNER_PLAYER_EDIT_END'
