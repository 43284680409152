import {
    Box,
    LinearProgress,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core'
import React, { useContext } from 'react'
import { URFormContext } from './context/URFormContext'

const useStyles = makeStyles((theme) => ({
    section: {
        padding: theme.spacing(2),
        marginBottom: '35px',
        paddingTop: '0px',
    },
    sectionTitleContainer: {
        position: 'relative',
        top: '-20px',
        background: 'white',
        width: 'fit-content',
        padding: '10px',
        paddingBottom: 0,
    },
    sectionTitle: {
        fontWeight: 'bolder',
    },
    sectionSubtitleContainer: {
        position: 'relative',
        top: '-15px',
    },
    sectionSubtitle: {
        lineHeight: '.80',
    },
    sectionLoading: {
        position: 'relative',
        top: '-20px',
    },
}))

const URFormSection = ({ title, subtitle, loading, children }) => {
    const { formLoading } = useContext(URFormContext)
    const classes = useStyles()
    return (
        <Paper className={classes.section} variant="outlined" square>
            <Box className={classes.sectionTitleContainer}>
                <Typography
                    variant="subtitle1"
                    className={classes.sectionTitle}
                >
                    {title}
                </Typography>
            </Box>
            <Box className={classes.sectionLoading}>
                {(loading || formLoading) && (
                    <LinearProgress color="secondary" />
                )}
            </Box>
            <Box className={classes.sectionSubtitleContainer}>
                <Typography
                    variant="subtitle2"
                    className={classes.sectionSubtitle}
                >
                    {subtitle}
                </Typography>
            </Box>
            {children}
        </Paper>
    )
}

export default URFormSection
