import React from 'react'
import { Grid } from '@material-ui/core'
import { gql, useMutation } from '@apollo/client'
import URForm from '../../../../components/URForm/URForm'
import URFormTextField from '../../../../components/URForm/URFormTextField'
import URFormSection from '../../../../components/URForm/URFormSection'
import URFormAddressSection from '../../../../components/URForm/URFormAddressSection'
import URFormBasicData from '../../../../components/URForm/URFormBasicData'
import { ADD_OWNER_NEW_PLAYER } from './constants'
import URButton from '../../../../components/URForm/URButton'
import Traits from './components/Traits'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import { cloneDeep, assignIn } from 'lodash'

const OWNER_GET_PLAYER_QUERY = gql`
    query ownerGetPlayerQuery($playerId: String) {
        player(id: $playerId) {
            id
            user {
                id
                phoneNumber
                email
                firstName
                lastName
                middleName
                zipCode
                state
                county
                city
                colonia
                street
                numberExt
                numberInt
            }
            playerAttributes {
                key
                values
            }
        }
    }
`

const OwnerNewPlayer = ({
    playerId,
    onOwnerMenuOptionSelected,
    onOwnerPlayerEditEnd,
}) => {
    const [addOwnerNewPlayer, { loading }] = useMutation(ADD_OWNER_NEW_PLAYER)

    const { enqueueSnackbar } = useSnackbar()

    const handleOnSubmit = (fields) => {
        const updateObject = (object, updatedProperties) => {
            const newObject = cloneDeep(object)
            return assignIn(newObject, updatedProperties)
        }

        let ownerNewPlayer = updateObject({}, fields)
        ownerNewPlayer.playerAttributes = Object.keys(
            ownerNewPlayer.playerAttributes
        ).map((attribute) => {
            return {
                key: attribute,
                values: Object.entries(
                    ownerNewPlayer.playerAttributes[attribute]
                ).reduce((accumulator, entry) => {
                    if (entry[1]) accumulator.push(entry[0])
                    return accumulator
                }, []),
            }
        })

        addOwnerNewPlayer({
            variables: {
                ...ownerNewPlayer,
            },
        })
            .then((response) => {
                enqueueSnackbar(
                    `Jugador ${
                        playerId ? 'editado' : 'registrado'
                    } exitosamente`
                )
                onOwnerPlayerEditEnd()
                onOwnerMenuOptionSelected(41)
            })
            .catch((error) => {
                enqueueSnackbar(error, { error: true })
            })
    }

    const transformPlayerQueryData = (data) => {
        let flatData = {}
        flatData.playerAttributes = {}
        data.player.playerAttributes.forEach((attribute) => {
            flatData.playerAttributes[attribute.key] = {}
            attribute.values.forEach((value) => {
                flatData.playerAttributes[attribute.key][value] = {}
                flatData.playerAttributes[attribute.key][value].checked = true
            })
        })

        //TODO create a function to flatten a object recursively
        const flat = (src, dest, parentKey, idFunction) => {
            const keys = Object.keys(src)
            keys.forEach((key) => {
                const value = src[key]
                if (typeof value === 'object' && value !== null) {
                    flat(value, dest, key, idFunction)
                } else {
                    if (
                        key === 'id' &&
                        typeof value === 'string' &&
                        value !== null
                    ) {
                        idFunction(parentKey, dest, key, value)
                    } else {
                        dest[key] = value
                    }
                }
            })

            return dest
        }

        const processId = (parentKey, dest, key, value) => {
            dest[parentKey + 'Id'] = value
        }

        return flat(data, flatData, '', processId)
    }

    return (
        <URForm
            onSubmit={handleOnSubmit}
            initialState={{ colonia: '', playerAttributes: {} }}
            initialization={
                playerId
                    ? {
                          query: OWNER_GET_PLAYER_QUERY,
                          variables: {
                              playerId: playerId,
                          },
                          transformData: transformPlayerQueryData,
                      }
                    : null
            }
        >
            <URFormBasicData
                title="Player Contact Data"
                subtitle="Ingresa la informacion basica del jugador"
            />

            <URFormAddressSection title="Player Address" />

            <Traits />

            <URFormSection
                title="Equipo"
                subtitle="Selecciona un equipo existente para tu nuevo jugador. 
                Si no cuenta con un equipo en este momento mas adelante encontrara el equipo perfecto"
            >
                <Grid item xs={12} sm={6} lg={6}>
                    <URFormTextField
                        fullWidth
                        id="team"
                        label="Equipo"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
            </URFormSection>

            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} lg={6}>
                    <URButton type="submit" loading={loading}>
                        Save
                    </URButton>
                </Grid>
            </Grid>
        </URForm>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
        playerId: state.ownerPlayerReducer.playerId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOwnerMenuOptionSelected: (menuOptionSelected) =>
            dispatch(actions.ownerMenuOptionSelected(menuOptionSelected)),
        onOwnerPlayerEditEnd: () => dispatch(actions.ownerPlayerEditEnd()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerNewPlayer)
