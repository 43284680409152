import {
    Collapse,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core'
import React, { useState } from 'react'
import UscorerPage from '../../components/UscorerPage/UscorerPage'
import LeagueAdmin from './OwnerLeague/OwnerLeague'
import OwnerNewTournament from './OwnerNewTournament/OwnerNewTournament'
import OwnerViewPlayer from './OwnerPlayer/OwnerViewPlayer/OwnerViewPlayer'
import OwnerNewPlayer from './OwnerPlayer/OwnerNewPlayer/OwnerNewPlayer'
import OwnerProfile from './OwnerProfile/OwnerProfile'
import OwnerNewTeam from './OwnerTeam/OwnerNewTeam/OwnerNewTeam'
import OwnerTeamViewer from './OwnerTeam/OwnerTeamViewer/OwnerTeamViewer'
import OwnerTournament from './OwnerTournament/OwnerTournament'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
}))

const OwnerHomePage = ({ menuOptionSelected, onOwnerMenuOptionSelected }) => {
    const classes = useStyles()
    const [open, setOpen] = useState({
        tournament: false,
        player: false,
    })

    const handleListItemClick = (event, index) => {
        onOwnerMenuOptionSelected(index)
    }

    const handleSubMenuClick = (event, menu) => {
        setOpen({
            [menu]: !open[menu],
        })
    }

    return (
        <UscorerPage>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={5}>
                    {/* <Grid item xs={12} sm={2} lg={2}>
                        <List component="nav" aria-label="main">
                            <ListItem
                                button
                                selected={menuOptionSelected === 0}
                                onClick={(event) =>
                                    handleListItemClick(event, 0)
                                }
                            >
                                <ListItemText primary="Profile" />
                            </ListItem>
                            <ListItem
                                button
                                selected={menuOptionSelected === 1}
                                onClick={(event) =>
                                    handleListItemClick(event, 1)
                                }
                            >
                                <ListItemText primary="League" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={(event) =>
                                    handleListItemClick(event, 21)
                                }
                            >
                                <ListItemText primary="My Tournaments" />
                            </ListItem>
                            <ListItem
                                button
                                selected={menuOptionSelected === 31}
                                onClick={(event) =>
                                    handleListItemClick(event, 31)
                                }
                            >
                                <ListItemText primary="My Teams" />
                            </ListItem>
                            <ListItem
                                button
                                selected={menuOptionSelected === 4}
                                onClick={(event) =>
                                    handleListItemClick(event, 41)
                                }
                            >
                                <ListItemText primary="My Players" />
                            </ListItem>
                        </List>
                    </Grid> */}
                    <Grid item xs={12} sm={12} lg={12}>
                        {menuOptionSelected === 0 && <OwnerProfile />}
                        {menuOptionSelected === 1 && <LeagueAdmin />}
                        {menuOptionSelected === 21 && <OwnerTournament />}
                        {menuOptionSelected === 22 && <OwnerNewTournament />}
                        {menuOptionSelected === 31 && <OwnerTeamViewer />}
                        {menuOptionSelected === 32 && <OwnerNewTeam />}
                        {menuOptionSelected === 41 && <OwnerViewPlayer />}
                        {menuOptionSelected === 42 && <OwnerNewPlayer />}
                    </Grid>
                </Grid>
            </Container>
        </UscorerPage>
    )
}

const mapStateToProps = (state) => {
    return {
        menuOptionSelected: state.ownerMenuReducer.menuOptionSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOwnerMenuOptionSelected: (menuOptionSelected) =>
            dispatch(actions.ownerMenuOptionSelected(menuOptionSelected)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerHomePage)
