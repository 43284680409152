import { useMutation, useQuery } from '@apollo/client'
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TeamCard from '../../../components/Team/TeamCard'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    selected: {
        borderColor: theme.palette.primary.main,
    },
    tournamentShield: {
        [theme.breakpoints.up('xs')]: {
            height: 125,
            backgroundSize: '100px 125px',
        },
        width: '100%',
        backgroundImage:
            'url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPnRvb2wtNyBjb3B5PC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMjMuODM3MjA5MywxMCBMMjMuODM3MjA5Myw1My45NTI0NDE5IEw0Ni45MjMyNTU4LDczLjE2NzQ0MTkgTDI5LjU0Mjc5MDcsODkuNTEyOTA3IEw3MC40NTcyMDkzLDg5LjUxMjkwNyBMNTMuMDc3NzkwNyw3My4xNjc0NDE5IEw3Ni4xNjI3OTA3LDUzLjk1MjQ0MTkgTDc2LjE2Mjc5MDcsMTAgTDIzLjgzNzIwOTMsMTAgWiBNODIuNzQxMTYyOCwxNS4yMzI1NTgxIEw4Mi43NDExNjI4LDQxLjQ1MDgxNCBMOTUsMzAuOTg1Njk3NyBMOTUsMTUuMjMyNTU4MSBMODIuNzQxMTYyOCwxNS4yMzI1NTgxIFogTTUsMTUuMjMyNTU4MSBMNSwzMC45ODU2OTc3IEwxNy4yNTg4MzcyLDQxLjQ1MDgxNCBMMTcuMjU4ODM3MiwxNS4yMzI1NTgxIEw1LDE1LjIzMjU1ODEgWiIgZmlsbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=")',
        backgroundSize: '200px 200px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
}))

function getSteps() {
    return ['Select Teams', 'Tournament Type', 'Settings']
}

const GET_TOURNAMENT_TYPES_QUERY = gql`
    {
        tournamentTypesBySport(sport: "FOOTBALL") {
            id
            name
            description
        }
        tournamentSettings(sport: "FOOTBALL") {
            id
            name
            description
            type
            options {
                key
                name
            }
        }
    }
`

const CREATE_NEW_TOURNAMENT_MUTATION = gql`
    mutation CreateNewTournament(
        $name: String
        $description: String
        $leagueId: String
        $teams: [String]
    ) {
        ownerNewTournament(
            tournament: {
                name: $name
                description: $description
                leagueId: $leagueId
                teams: $teams
            }
        ) {
            id
            name
        }
    }
`

const OwnerNewTournament = ({ leagueId, teams }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [buttonBackDisabled, setButtonBackDisabled] = useState(true)
    const [buttonNextDisabled, setButtonNextDisabled] = useState(false)
    const [tournamentTypes, setTournamentTypes] = useState([])
    const classes = useStyles()

    const { data } = useQuery(GET_TOURNAMENT_TYPES_QUERY)
    const [createTournament, { loading }] = useMutation(
        CREATE_NEW_TOURNAMENT_MUTATION
    )

    const steps = getSteps()

    const hanldeNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleOnClickTournamentType = (tournament) => {
        //TODO make a general function for these cases
        const tournamentIndex = tournamentTypes.findIndex(
            (t) => t.id === tournament.id
        )

        const newTournamentTypes = tournamentTypes.map((tournament) => {
            return {
                ...tournament,
                selected: false,
            }
        })

        const newTournament = {
            ...tournamentTypes[tournamentIndex],
            selected: !tournamentTypes[tournamentIndex].selected,
        }
        setTournamentTypes([
            ...newTournamentTypes.slice(0, tournamentIndex),
            newTournament,
            ...newTournamentTypes.slice(tournamentIndex + 1),
        ])
    }

    const tournamentShield = () => {
        return <Box className={classes.tournamentShield} />
    }

    const handleCreateTournament = () => {
        const teamsId = teams.map((team) => {
            return team.id
        })
        createTournament({
            variables: {
                name: 'Tournament',
                description: 'Tournamnet test',
                leagueId: leagueId,
                teams: teamsId,
            },
        })
    }

    useEffect(() => {
        setButtonNextDisabled(activeStep === 2)
        setButtonBackDisabled(activeStep === 0)
    }, [activeStep])

    useEffect(() => {
        if (data) {
            setTournamentTypes(data.tournamentTypesBySport)
        }
    }, [data])

    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </Grid>
            </Grid>
            {activeStep === 0 && (
                <Box>
                    <Grid
                        container
                        spacing={2}
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        {teams.map((team) => (
                            <Grid item xs={6} sm={3} lg={3}>
                                <TeamCard team={team} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {activeStep == 1 && (
                <Box>
                    <Grid
                        container
                        spacing={2}
                        justify="space-around"
                        alignItems="center"
                    >
                        {tournamentTypes?.map((tournament) => (
                            <Grid item xs={6} sm={3} lg={3} key={tournament.id}>
                                <Card
                                    className={
                                        classes.root && tournament.selected
                                            ? classes.selected
                                            : ''
                                    }
                                    variant="outlined"
                                >
                                    <CardActionArea
                                        onClick={(event) =>
                                            handleOnClickTournamentType(
                                                tournament
                                            )
                                        }
                                    >
                                        <CardMedia
                                            component={tournamentShield}
                                        />
                                        <CardContent>
                                            <Typography>
                                                {tournament.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {activeStep === 2 && (
                <Box>
                    <Grid
                        container
                        spacing={2}
                        justify="space-around"
                        alignItems="center"
                    >
                        {data?.tournamentSettings?.map((setting) => (
                            <div>
                                <label>{setting.name}</label>
                                {setting.options.map((option) => (
                                    <FormGroup key={option.key} row>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={option.name}
                                        />
                                    </FormGroup>
                                ))}
                            </div>
                        ))}
                    </Grid>
                </Box>
            )}
            <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} sm={3} lg={3}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleBack}
                        disabled={buttonBackDisabled}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    {activeStep === 2 ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleCreateTournament}
                        >
                            Create Tournament
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={hanldeNext}
                            disabled={buttonNextDisabled}
                        >
                            Next
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
        teams: state.ownerTournamentReducer.teams,
    }
}

export default connect(mapStateToProps)(OwnerNewTournament)
