import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { URFormContext } from './context/URFormContext'

const URFormAttribute = ({ id, checkKey, checkValue }) => {
    const { fields, handleOnChange } = useContext(URFormContext)

    const handleCheckboxChange = (event, key, value) => {
        if (!fields[id][key]) {
            fields[id][key] = {}
        }

        fields[id][key][value] = event.target.checked

        handleOnChange({
            preventDefault: () => {
                //Do Nothing
            },
            target: {
                id: id,
                value: fields[id],
            },
        })
    }

    return (
        <FormGroup key={checkValue} row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={
                            fields[id][checkKey]
                                ? fields[id][checkKey][checkValue]
                                : false
                        }
                        onChange={(event) => {
                            handleCheckboxChange(event, checkKey, checkValue)
                        }}
                        name={id}
                    />
                }
                label={checkValue}
            />
        </FormGroup>
    )
}

URFormAttribute.propTypes = {
    __TYPE: PropTypes.string,
}

URFormAttribute.defaultProps = {
    __TYPE: 'URFormCheckbox',
}

export default URFormAttribute
