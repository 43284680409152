import React from 'react'
import { connect } from 'react-redux'
import { DT, OWNER, PLAYER } from '../constants'
import DTHomePage from '../DT/DTHomePage/DTHomePage'
import OwnerHomePage from '../Owner/OwnerHomePage/OwnerHomePage'
import PlayerHomePage from '../Player/PlayerHomePage/PlayerHomePage'
import Public from '../Public/Public'

const Auth = ({ isAuthenticated, userType }) => {
    if (!isAuthenticated) {
        return <Public />
    }

    switch (userType) {
        case OWNER:
            return <OwnerHomePage />
        case DT:
            return <DTHomePage />
        case PLAYER:
            return <PlayerHomePage />
        default:
            return <Public />
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authReducer.token !== null,
        userType: state.authReducer.userType,
    }
}

export default connect(mapStateToProps)(Auth)
