import { useLazyQuery, useMutation } from '@apollo/client'
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Paper,
    Switch,
    Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'

import { connect } from 'react-redux'

import * as actions from '../../../../store/actions/index'
import TeamCard from '../../../../components/Team/TeamCard'
import { useSnackbar } from 'notistack'
import UscorerCard from '../../../../components/UscorerCard/UscorerCard'

const GET_TEAMS_BY_LEAGUE_ID_QUERY = gql`
    query teamsByLeagueId($leagueId: String) {
        teamsByLeagueId(leagueId: $leagueId) {
            id
            teamName
            dt {
                user {
                    firstName
                    lastName
                }
            }
        }
    }
`

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 270,
        maxHeight: 270,
    },
    selected: {
        borderColor: theme.palette.primary.main,
    },
    paperForm: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))

const OWNER_REMOVE_TEAM_MUTATION = gql`
    mutation OwnerRemoveTeam($teamId: String) {
        ownerRemoveTeam(id: $teamId)
    }
`

const OwnerTeamViewer = ({
    leagueId,
    onOwnerMenuOptionSelected,
    onOwnerTournamentCreateStart,
    onOwnerTeamEditStart,
}) => {
    const classes = useStyles()
    const [teams, setTeams] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [createTournamentDisabled, setCreateTournamentDisabled] =
        useState(true)
    const [editTeamDisabled, setEditTeamDisabled] = useState(true)
    const [removeTeamDisabled, setRemoveTeamDisabled] = useState(true)
    const [getTeams, { loading, data }] = useLazyQuery(
        GET_TEAMS_BY_LEAGUE_ID_QUERY,
        {
            fetchPolicy: 'no-cache',
        }
    )
    const [ownerRemoveTeam] = useMutation(OWNER_REMOVE_TEAM_MUTATION)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        getTeams({
            variables: {
                leagueId,
            },
        })
    }, [leagueId, getTeams])

    useEffect(() => {
        const selectedTeams = teams.filter(
            (team) => team.selected === true
        ).length
        setCreateTournamentDisabled(selectedTeams < 4)
        setEditTeamDisabled(selectedTeams < 1 || selectedTeams > 1)
        setRemoveTeamDisabled(selectedTeams < 1 || selectedTeams > 1)
    }, [teams])

    useEffect(() => {
        if (!loading && data) {
            setTeams(data.teamsByLeagueId)
        }
    }, [data, loading])

    const handleOnChangeSelectAll = (event) => {
        const checked = event.target.checked
        setSelectAll(checked)
        const updatedTeams = teams.map((t) => {
            return {
                ...t,
                selected: checked,
            }
        })
        setTeams(updatedTeams)
    }

    const handleOnClickTeam = (team) => {
        const teamIndex = teams.findIndex((t) => t.id === team.id)
        const newTeam = {
            ...teams[teamIndex],
            selected: !teams[teamIndex].selected,
        }
        setTeams([
            ...teams.slice(0, teamIndex),
            newTeam,
            ...teams.slice(teamIndex + 1),
        ])
    }

    const handleOnClickCreateTournament = () => {
        onOwnerTournamentCreateStart(
            teams.filter((team) => team.selected === true)
        )
        onOwnerMenuOptionSelected(22)
    }

    const handleOnSubmit = (event) => {
        event.preventDefault()
    }

    const handleNewTeam = () => {
        onOwnerMenuOptionSelected(32)
    }

    const handleEditTeam = (team) => {
        onOwnerTeamEditStart(team)
        onOwnerMenuOptionSelected(32)
    }

    const handleRemoveTeam = (team) => {
        //TODO show confirmation modal
        //Refactor in components
        ownerRemoveTeam({
            variables: {
                teamId: team.id,
            },
        })
            .then((response) => {
                enqueueSnackbar('Team removed successfully!')
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Paper
                        component="form"
                        className={classes.paperForm}
                        onSubmit={handleOnSubmit}
                    >
                        <InputBase
                            fullWidth
                            className={classes.input}
                            placeholder="Search for a team"
                            inputProps={{ 'aria-label': 'search for a team' }}
                        />
                        <IconButton
                            type="submit"
                            className={classes.iconButton}
                            aira-label="Search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    <FormGroup row>
                        <FormControlLabel
                            label="Select All"
                            control={
                                <Switch
                                    checked={selectAll}
                                    onChange={handleOnChangeSelectAll}
                                    color="secondary"
                                    name="selectAll"
                                    inputProps={{
                                        'aria-label': 'Select All',
                                    }}
                                />
                            }
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                justify="felx-start"
                alignItems="flex-start"
            >
                {loading && 'Cargando ...'}
                {!loading &&
                    teams.map((team) => (
                        <Grid item xs={6} sm={3} lg={3}>
                            <UscorerCard
                                onEdit={handleEditTeam}
                                onRemove={handleRemoveTeam}
                                elements={teams}
                                element={team}
                                setElements={setTeams}
                                backgroundImage={
                                    'url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTUwLjE3MSw2LjI4OGMxMS42MzcsOC4wMTMsMzAuMTkxLDExLjg5OCwzOC4xMDEsMTMuMjZjMC4zMDEsMi43NzIsMC40NTUsNS41MjcsMC40NTUsOC4yMjMgIGMwLDI3LjYzNC0xNC43MjgsNTIuOTMtMzguNjEyLDY2LjY0MkMyNi4yMjksODAuNjk3LDExLjUsNTUuNDAyLDExLjUsMjcuNzcxYzAtMi42NjksMC4xNTQtNS40MDMsMC40NTUtOC4xNyAgQzIwLjAzMSwxOC4zMTgsMzguOTQ2LDE0LjU2OCw1MC4xNzEsNi4yODggTTUwLjAzOSwwQzM4LjIxNiwxMS42OTksNy41ODgsMTUuMjgxLDcuNTg4LDE1LjI4MWMtMC42MjgsNC4wNzgtMC45OCw4LjI0LTAuOTgsMTIuNDkgIGMwLDMxLjMyNywxNy42NCw1OC41MTIsNDMuNTA2LDcyLjIyOWMyNS44Ny0xMy43MTgsNDMuNTA0LTQwLjkwMiw0My41MDQtNzIuMjI5YzAtNC4yNS0wLjM0Ni04LjQxMi0wLjk3My0xMi40OSAgQzkyLjY0NiwxNS4yODEsNjIuODIyLDExLjM0LDUwLjAzOSwwTDUwLjAzOSwweiI+PC9wYXRoPjxwYXRoIGQ9Ik0yMC43MzgsMjYuMDE1Yy0wLjQzNSwyLjgyNC0wLjY3Nyw1LjcwMi0wLjY3Nyw4LjYzOGMwLDIxLjYzMywxMi4xOSw0MC40MDcsMzAuMDUzLDQ5Ljg4MiAgYzE3Ljg2NC05LjQ3NiwzMC4wNTItMjguMjQ5LDMwLjA1Mi00OS44ODJjMC0yLjkzNi0wLjI0Ny01LjgxNC0wLjY3NC04LjYzOGMwLDAtMjAuNjAyLTIuNzE5LTI5LjQzMi0xMC41NTEgIEM0MS44OTIsMjMuNTQyLDIwLjczOCwyNi4wMTUsMjAuNzM4LDI2LjAxNXoiPjwvcGF0aD48L3N2Zz4=")'
                                }
                            >
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    spacing={2}
                                    style={{
                                        height: '100%',
                                    }}
                                >
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            {team.teamName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography va>
                                            {team?.dt?.user?.firstName}{' '}
                                            {team?.dt?.user?.lastName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </UscorerCard>
                        </Grid>
                    ))}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOwnerMenuOptionSelected: (menuOptionSelected) =>
            dispatch(actions.ownerMenuOptionSelected(menuOptionSelected)),
        onOwnerTournamentCreateStart: (teams) =>
            dispatch(actions.ownerTournamentCreateStart(teams)),
        onOwnerTeamEditStart: (team) => {
            dispatch(actions.ownerTeamEditStart(team))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerTeamViewer)
