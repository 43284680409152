import { useMutation } from '@apollo/client'
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useCallback, useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import { connect } from 'react-redux'
import { AUTH_TOKEN } from '../../constants'
import * as actions from '../../store/actions/index'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    facebookButton: {
        width: '100%',
        minWidth: '400px',
        backgroundColor: '#4267B2',
        fontFamily: 'Helvetica,sans-serif',
        fontWeight: '600',
        color: '#fff',
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: 'calc(.27548vw + 10.71074px)',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'background-color .3s,border-color .3s',
        border: 'calc(.06887vw + .67769px) solid #4c69ba',
        padding: 'calc(.34435vw + 11.38843px) calc(.34435vw + 15.38843px)',
    },
}))

const LOGIN = gql`
    mutation Login($email: String, $password: String, $facebookLogin: Boolean) {
        login(
            email: $email
            password: $password
            facebookLogin: $facebookLogin
        ) {
            id
            firstName
            lastName
            pictureUrl
            type
            token
        }
    }
`

const Login = ({ onAuth, onAuthSuccess }) => {
    const [user, setUser] = useState({})

    const [login] = useMutation(LOGIN)

    const classes = useStyles()

    const handleInputOnChange = (event) => {
        event.preventDefault()
        setUser({
            ...user,
            [event.target.id]: event.target.value,
        })
    }

    const handleFacebookResponse = (response) => {
        console.log(response)
        setUser({
            firstName: response.name,
            email: response.email,
            pictureUrl: response.picture.data.url,
            facebookLogin: true,
        })
    }

    const handleLogin = useCallback(
        (user) => {
            onAuth(user.email, user.password, false)
            login({
                variables: {
                    ...user,
                },
            })
                .then((response) => {
                    const { id, token, pictureUrl, firstName, lastName, type } =
                        response.data.login
                    onAuthSuccess(
                        id,
                        token,
                        firstName,
                        lastName,
                        pictureUrl,
                        type
                    )
                    localStorage.setItem(AUTH_TOKEN, token)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [login, onAuth, onAuthSuccess]
    )

    useEffect(() => {
        if (user.facebookLogin) {
            handleLogin(user)
        }
    }, [user, handleLogin])

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid
                container
                spacing={2}
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                    >
                        <Grid item>
                            <Box style={{ textAlign: 'center' }}>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    component="h2"
                                >
                                    Login into your account
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box style={{ textAlign: 'center' }}>
                                <FacebookLogin
                                    cssClass={classes.facebookButton}
                                    appId="160834444087767"
                                    autoLoad={false}
                                    fields="name, email, picture"
                                    textButton="Login with Facebook"
                                    callback={handleFacebookResponse}
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box style={{ textAlign: 'center' }}>
                                <Typography color="primary">Or</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    value={user.email}
                                    onChange={handleInputOnChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <TextField
                                    type="password"
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    value={user.password}
                                    onChange={handleInputOnChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleLogin}
                                >
                                    Login
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.token !== null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, isSignUp) => {
            dispatch(actions.authUser(email, password, isSignUp))
        },
        onAuthSuccess: (
            userId,
            token,
            firstName,
            lastName,
            pictureUrl,
            type
        ) => {
            dispatch(
                actions.authSuccess(
                    userId,
                    token,
                    firstName,
                    lastName,
                    pictureUrl,
                    type
                )
            )
        },
        onAuthFail: (error) => {
            dispatch(actions.authFail(error))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
