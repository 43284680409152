import * as actionTypes from '../actions/actionTypes'
import { cloneDeep, assignIn } from 'lodash'

const initialState = {
    menuOptionSelected: 0,
}

const updateObject = (object, updatedProperties) => {
    const newObject = cloneDeep(object)
    return assignIn(newObject, updatedProperties)
}

const ownerMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OWNER_MENU_SELECTED:
            return updateObject(state, {
                menuOptionSelected: action.menuOptionSelected,
            })
        default:
            return state
    }
}

export default ownerMenuReducer
