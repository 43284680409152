import { useApolloClient } from '@apollo/client'
import {
    AppBar,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@material-ui/core'
import { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { AUTH_TOKEN } from '../../constants'
import * as actions from '../../store/actions/index'
import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import OwnerInfo from './components/OwnerInfo'
import AccountCircle from '@material-ui/icons/AccountCircle'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    menuBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
        width: '100px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: 'bolder',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontWeight: 'bolder',
            color: theme.palette.primary.contrastText,
        },
    },
    subtitle: {
        marginRight: '10px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            textTransform: 'uppercase',
            color: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    menuButton: {
        textTransform: 'uppercase',
        fontWeight: 'bolder',
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}))

const MenuBar = ({
    isAuthenticated,
    firstName,
    lastName,
    userType,
    onLogout,
    onLogoutSuccess,
    onOwnerMenuOptionSelected,
}) => {
    const history = useHistory()
    const classes = useStyles()
    const client = useApolloClient()
    const [open, setopen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const menuOpen = Boolean(anchorEl)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const toggleDrawer = () => {
        setopen(!open)
    }

    const handleCreateAccountClick = () => {
        history.push('/signUp')
    }

    const handleLoginClick = () => {
        history.push('/login')
    }

    const handleLogoutClick = () => {
        onLogout()
        // Evict and garbage-collect the cached user object
        client.cache.evict({ fieldName: 'me' })
        client.cache.gc()
        // Remove user details from localStorage
        localStorage.removeItem(AUTH_TOKEN)

        history.push('/')
        onLogoutSuccess()
    }

    const handleMyTournaments = () => {
        onOwnerMenuOptionSelected(21)
    }

    const handleMyTeams = () => {
        onOwnerMenuOptionSelected(31)
    }

    const handleMyPlayers = () => {
        onOwnerMenuOptionSelected(41)
    }

    const handleProfile = () => {
        onOwnerMenuOptionSelected(0)
        handleMenuClose()
    }

    const handleMyAccount = () => {
        onOwnerMenuOptionSelected(1)
        handleMenuClose()
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button onClick={handleMyTournaments}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Tournaments" />
                </ListItem>
                <ListItem button onClick={handleMyTeams}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Teams" />
                </ListItem>
                <ListItem button onClick={handleMyPlayers}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Players" />
                </ListItem>
            </List>
            <Divider />
        </div>
    )

    return (
        <Fragment>
            <AppBar position="fixed" className={classes.menuBar}>
                <Toolbar>
                    {isAuthenticated && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={toggleDrawer}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography className={classes.title}>
                        <Link to="/" className={classes.title}>
                            USCORER
                        </Link>
                    </Typography>
                    {isAuthenticated && (
                        <>
                            <Typography className={classes.subtitle}>
                                <Link
                                    to="/"
                                    className={classes.subtitle}
                                    onClick={handleMyTournaments}
                                >
                                    My Tournaments
                                </Link>
                            </Typography>
                            <Typography className={classes.subtitle}>
                                <Link
                                    to="/"
                                    className={classes.subtitle}
                                    onClick={handleMyTeams}
                                >
                                    My Teams
                                </Link>
                            </Typography>
                            <Typography
                                className={classes.subtitle}
                                style={{ flexGrow: 1 }}
                            >
                                <Link
                                    to="/"
                                    className={classes.subtitle}
                                    onClick={handleMyPlayers}
                                >
                                    My Players
                                </Link>
                            </Typography>
                            {userType === 'OWNER' && <OwnerInfo />}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={menuOpen}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleProfile}>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleMyAccount}>
                                    My account
                                </MenuItem>
                                <MenuItem onClick={handleLogoutClick}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                    {!isAuthenticated && (
                        <>
                            <Button color="inherit" onClick={handleLoginClick}>
                                Login
                            </Button>
                            <Button
                                color="inherit"
                                onClick={handleCreateAccountClick}
                            >
                                Sign Up
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={open} onClose={toggleDrawer}>
                {drawer}
            </Drawer>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authReducer.isAuthenticated,
        firstName: state.authReducer.firstName,
        lastName: state.authReducer.lastName,
        userType: state.authReducer.userType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onLogoutSuccess: () => dispatch(actions.logoutSuccess()),
        onOwnerMenuOptionSelected: (menuOptionSelected) =>
            dispatch(actions.ownerMenuOptionSelected(menuOptionSelected)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar)
