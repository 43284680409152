import { useMutation } from '@apollo/client'
import { Grid } from '@material-ui/core'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'
import React from 'react'
import { connect } from 'react-redux'
import URButton from '../../../../components/URForm/URButton'
import URForm from '../../../../components/URForm/URForm'
import URFormAddressSection from '../../../../components/URForm/URFormAddressSection'
import URFormBasicData from '../../../../components/URForm/URFormBasicData'
import URFormSection from '../../../../components/URForm/URFormSection'
import URFormTextField from '../../../../components/URForm/URFormTextField'
import * as actions from '../../../../store/actions/index'

const OWNER_NEW_TEAM_MUTATION = gql`
    mutation OwnerNewTeam(
        $teamName: String
        $phoneNumber: String
        $email: String
        $firstName: String
        $lastName: String
        $middleName: String
        $zipCode: String
        $state: String
        $county: String
        $city: String
        $colonia: String
        $street: String
        $numberExt: String
        $numberInt: String
        $country: String
        $birthDate: String
        $leagueId: String
        $teamId: String
        $userId: String
    ) {
        ownerNewTeam(
            team: {
                teamName: $teamName
                phoneNumber: $phoneNumber
                email: $email
                firstName: $firstName
                lastName: $lastName
                middleName: $middleName
                zipCode: $zipCode
                state: $state
                county: $county
                city: $city
                colonia: $colonia
                street: $street
                numberExt: $numberExt
                numberInt: $numberInt
                country: $country
                birthDate: $birthDate
                leagueId: $leagueId
                teamId: $teamId
                userId: $userId
            }
        ) {
            teamName
        }
    }
`

const OWNER_GET_TEAM_QUERY = gql`
    query ownerGetTeamQuery($teamId: String) {
        team(id: $teamId) {
            id
            teamName
            dt {
                user {
                    id
                    phoneNumber
                    email
                    firstName
                    lastName
                    middleName
                    zipCode
                    state
                    county
                    city
                    colonia
                    street
                    numberExt
                    numberInt
                }
            }
        }
    }
`

const OwnerNewTeam = ({
    leagueId,
    teamId,
    edit,
    onOwnerMenuOptionSelected,
    onOwnerEditSuccessfully,
}) => {
    const [addTeam, { loading }] = useMutation(OWNER_NEW_TEAM_MUTATION)
    const { enqueueSnackbar } = useSnackbar()

    const handleOnSubmit = (fields) => {
        addTeam({
            variables: {
                ...fields,
                leagueId,
            },
        })
            .then((response) => {
                enqueueSnackbar(
                    edit
                        ? 'Team updated successfully!'
                        : 'Team created successfully!'
                )
                onOwnerMenuOptionSelected(31)
                onOwnerEditSuccessfully()
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const transformTeamQuery = (data) => {
        //TODO create a function to flatten a object recursively
        const flat = (src, dest, parentKey, idFunction) => {
            const keys = Object.keys(src)
            keys.forEach((key) => {
                const value = src[key]
                if (typeof value === 'object' && value !== null) {
                    flat(value, dest, key, idFunction)
                } else {
                    if (
                        key === 'id' &&
                        typeof value === 'string' &&
                        value !== null
                    ) {
                        idFunction(parentKey, dest, key, value)
                    } else {
                        dest[key] = value
                    }
                }
            })

            return dest
        }

        const processId = (parentKey, dest, key, value) => {
            dest[parentKey + 'Id'] = value
        }

        let flatData = {}
        return flat(data, flatData, '', processId)
    }

    return (
        <URForm
            onSubmit={handleOnSubmit}
            initialization={
                teamId
                    ? {
                          query: OWNER_GET_TEAM_QUERY,
                          variables: {
                              teamId: teamId,
                          },
                          transformData: transformTeamQuery,
                      }
                    : null
            }
        >
            <URFormSection
                title="Team"
                subtitle="Introduce la informacion del equipo"
            >
                <Grid item xs={12} sm={12} lg={12}>
                    <URFormTextField
                        fullWidth
                        id="teamName"
                        label="Nombre de Equipo"
                        variant="outlined"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </Grid>
            </URFormSection>

            <URFormBasicData
                title="DT Info"
                subtitle="Introduce la informacion de contacto del DT del equipo"
                path="team.dt.user"
            />

            <URFormAddressSection
                title="DT Address"
                subtitle="Introduce la direccion del DT"
            />

            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} lg={6}>
                    <URButton type="submit" loading={loading}>
                        Save
                    </URButton>
                </Grid>
            </Grid>
        </URForm>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
        teamId: state.ownerTeamReducer.teamId,
        edit: state.ownerTeamReducer.edit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOwnerMenuOptionSelected: (menuOptionSelected) =>
            dispatch(actions.ownerMenuOptionSelected(menuOptionSelected)),
        onOwnerEditSuccessfully: () => dispatch(actions.ownerTeamEditEnd()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerNewTeam)
