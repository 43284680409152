import { useQuery } from '@apollo/client'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import URFormAttribute from '../../../../../components/URForm/URFormCheckbox'
import URFormSection from '../../../../../components/URForm/URFormSection'
import { OWNER_NEW_PLAYER_QUERY } from '../constants'

const Traits = () => {
    const { data, loading } = useQuery(OWNER_NEW_PLAYER_QUERY)

    return (
        <URFormSection
            title="Player Traits"
            subtitle="Estos son los rasgos basicos del jugador. En su perfil podra editar todos sus 
                rasgos tales como jugador como velocidad, sprint, dribbling, etc"
            loading={loading}
        >
            <Grid container>
                {loading && 'Loading ...'}
                {data?.playerAttributesBySportAndType &&
                    data?.playerAttributesBySportAndType.map((attribute) => (
                        <Grid item xs={12} sm={3} lg={3} key={attribute.key}>
                            <Typography>{attribute.name}</Typography>
                            {attribute.values &&
                                attribute.values.map((value) => (
                                    <URFormAttribute
                                        key={value}
                                        id="playerAttributes"
                                        checkKey={attribute.key}
                                        checkValue={value}
                                    />
                                ))}
                        </Grid>
                    ))}
            </Grid>
        </URFormSection>
    )
}

export default Traits
