import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Paper,
    Switch,
    Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import UscorerCard from '../../../components/UscorerCard/UscorerCard'

const useStyles = makeStyles((theme) => ({
    paperForm: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))

const OWNER_TOURNAMENTS_QUERY = gql`
    query tournamentsByLeagueId($leagueId: String) {
        tournamentsByLeagueId(leagueId: $leagueId) {
            id
            name
            teams {
                id
                teamName
            }
        }
    }
`

const OwnerTournament = ({ leagueId }) => {
    const classes = useStyles()
    const [selectAll, setSelectAll] = useState(false)
    const [tournaments, setTournaments] = useState([])
    const [getTournaments, { loading, data }] = useLazyQuery(
        OWNER_TOURNAMENTS_QUERY,
        {
            fetchPolicy: 'no-cache',
        }
    )

    useEffect(() => {
        getTournaments({
            variables: {
                leagueId,
            },
        })
    }, [leagueId, getTournaments])

    useEffect(() => {
        if (data && !loading) {
            setTournaments(data.tournamentsByLeagueId)
        }
    }, [data, loading])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Paper component="form" className={classes.paperForm}>
                        <InputBase
                            fullWidth
                            className={classes.input}
                            placeholder="Search for a tournament"
                            inputProps={{
                                'aria-label': 'search for a tournament',
                            }}
                        />
                        <IconButton
                            type="submit"
                            className={classes.iconButton}
                            aira-label="Search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                    <FormGroup row>
                        <FormControlLabel
                            label="Select All"
                            control={
                                <Switch
                                    checked={selectAll}
                                    color="secondary"
                                    name="selectAll"
                                    inputProps={{
                                        'aria-label': 'Select All',
                                    }}
                                />
                            }
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                justify="felx-start"
                alignItems="flex-start"
            >
                {loading && 'Cargando ...'}
                {!loading &&
                    tournaments?.map((tournament) => (
                        <Grid key={tournament.id} item xs={6} sm={3} lg={3}>
                            <UscorerCard
                                elements={tournaments}
                                element={tournament}
                                setElements={setTournaments}
                                backgroundImage={
                                    'url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPnRvb2wtNyBjb3B5PC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMjMuODM3MjA5MywxMCBMMjMuODM3MjA5Myw1My45NTI0NDE5IEw0Ni45MjMyNTU4LDczLjE2NzQ0MTkgTDI5LjU0Mjc5MDcsODkuNTEyOTA3IEw3MC40NTcyMDkzLDg5LjUxMjkwNyBMNTMuMDc3NzkwNyw3My4xNjc0NDE5IEw3Ni4xNjI3OTA3LDUzLjk1MjQ0MTkgTDc2LjE2Mjc5MDcsMTAgTDIzLjgzNzIwOTMsMTAgWiBNODIuNzQxMTYyOCwxNS4yMzI1NTgxIEw4Mi43NDExNjI4LDQxLjQ1MDgxNCBMOTUsMzAuOTg1Njk3NyBMOTUsMTUuMjMyNTU4MSBMODIuNzQxMTYyOCwxNS4yMzI1NTgxIFogTTUsMTUuMjMyNTU4MSBMNSwzMC45ODU2OTc3IEwxNy4yNTg4MzcyLDQxLjQ1MDgxNCBMMTcuMjU4ODM3MiwxNS4yMzI1NTgxIEw1LDE1LjIzMjU1ODEgWiIgZmlsbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=");'
                                }
                            >
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    spacing={2}
                                    style={{
                                        height: '100%',
                                    }}
                                >
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            {`${tournament.name}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </UscorerCard>
                        </Grid>
                    ))}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        leagueId: state.ownerReducer.leagueId,
    }
}

export default connect(mapStateToProps)(OwnerTournament)
